.form--header-search {
  max-width: 215px;
  width: 100%;
  
  padding: 0;

  .field-wrapper {
    position: relative;
    margin: 0;
  }

  .field {
    background-color: #F5F5F5;
    margin: 0;

    input {
      $fs: 13;
      background-color: transparent;
      width: calc(100% - 35px);
      font-family: $title-font-family;
      font-size: rem($fs);
      letter-spacing: ls(1.38, $fs);
      padding: 0 em(10, $fs);
    }
  }

  .field::after {
    background-color: $blue;
  }

  .btn--small {
    color: $blue;
    position: absolute;
    top: 3px;
    right: 0;

    @include btn-hover() {
      color: $blue;
    }
  }
}

.trigger {
  @include above($tablet) {
    display: none;
  }
}