$icn_size: 35px;

$iconColors: (
  'red': $red,
  'blue': $blue,
  'white': $white
);

.svg-sprite {
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  left: 0; 
  overflow: hidden;
}

.icn {
  width: $icn_size;
  height: $icn_size;
  display: inline-block;
  position: relative;

  svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  @each $name, $color in $iconColors {
    &--#{$name} {
      svg { fill: $color; }
    }
  }
}


.hamburger {
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    position: absolute;

    &, &::before, &::after {
      display: block;
      position: relative;
      width: 29px;
      height: 3px;
      background-color: $blue;
    }

    &::before, &::after {
      content: '';
      position: absolute;
    }
    
    &::before {
      top: -8px;
    }

    &::after {
      bottom: -8px;
    }
  }
}