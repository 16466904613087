// ==========================================================================
// Variables
// ==========================================================================

$root-box-sizing: border-box;

$mobile: 480px;
$tablet-xs: 600px;
$tablet: 768px;
$desktop-sm: 990px;
$desktop: 1100px;
$desktop-md: 1280px;
$desktop-hd: 1440px;
$hd: 1650px;
$desktop-xxl: 2000px;

$base-margin: 20px;

// Colors
$black: #010102;
$white: #fff;
$grey: #ddd;
$red: #FB3E4B;
$blue: #2E3554;
$darkblue: #242A43;
$darkgreen: #87CFC4;
$lightorange: #FBBFA1;
$lightblue: #B0CDEA;
$lightgreen: #87CFC4;
$text-subdued: #AEAEAE;
$error-color: $red;


// Paths
$base-path: '../';
$base-font-path: $base-path + 'fonts/';
$base-img-path:  $base-path + 'img/';




// Fonts
$system-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$base-font-family: 'Open Sans', sans-serif;
$title-font-family: 'Raleway', sans-serif;


// Transitions
$base-transition: 'all .3s ease';
