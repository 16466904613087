.mega-menu-wrapper {
  background-color: #F5F5F5;
  overflow: hidden;
  transition-property: transform, box-shadow;
  transition-duration: .3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  transform: translateY(-110%);
  box-shadow: 0 3px 20px rgba(#000, 0);

  &.is-open {
    transform: translateY(0);
    box-shadow: 0 3px 20px rgba(#000, .1);
  }

  @include above($tablet) {
    top: $header-height-tablet;
  }

  @include above($desktop) {
    top: $header-height;
  }
}

.nav--mega {
  padding: 20px 0;
}