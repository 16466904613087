$header-height-mobile: 48px;
$header-height: 83px;
$header-height-tablet: 123px;

.site-header {
  position: sticky;
  top: 0px;
  background-color: $white;
  z-index: 100;
  box-shadow: 0 3px 20px rgba(#000, .1);
  height: $header-height-mobile;
  
  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include above($tablet) {
      padding-top: 40px;
      overflow: hidden;
    }

    @include above($desktop) {
      flex-wrap: nowrap;
      padding-top: 0;
    }
  }



  .logo           { order: 2; }
  .nav--primary   { order: 3; }
  .nav--secondary { 
    order: 1; 
    
    @include above($desktop) {
      order: 4;
    }
  }
  .form--header-search { order: 5; }

  @include above($tablet) {
    height: $header-height-tablet;    
  }

  @include above($desktop) {
    height: $header-height;
  }

  .form--header-search,
  .nav--primary,
  .nav--secondary {
    display: none;
    
    @include above($tablet) {
      display: block;
    }
  }
}

