// ==========================================================================
// Fonts
// ==========================================================================
// Font-weights
// Thin (Hairline): 100
// Extra Light (Ultra Light): 200
// Light: 300
// Normal: 400
// Medium: 500
// Semi Bold (Demi Bold): 600
// Bold: 700
// Extra Bold (Ultra Bold): 800
// Black (Heavy): 900
// ==========================================================================

$base-font-path: if(variable-exists(base-font-path), $base-font-path, "../fonts/");

$fonts: (
  'Raleway': (
    (100, normal, "raleway/raleway-thin-webfont"),
    (100, italic, "raleway/raleway-thinitalic-webfont"),
    (200, normal, "raleway/raleway-extralight-webfont"),
    (200, italic, "raleway/raleway-extralightitalic-webfont"),
    (300, normal, "raleway/raleway-light-webfont"),
    (300, italic, "raleway/raleway-lightitalic-webfont"),
    (400, normal, "raleway/raleway-regular-webfont"),
    (400, italic, "raleway/raleway-italic-webfont"),
    (500, normal, "raleway/raleway-medium-webfont"),
    (500, italic, "raleway/raleway-mediumitalic-webfont"),
    (600, normal, "raleway/raleway-semibold-webfont"),
    (600, italic, "raleway/raleway-semibolditalic-webfont"),
    (700, normal, "raleway/raleway-bold-webfont"),
    (700, italic, "raleway/raleway-bolditalic-webfont"),
    (800, normal, "raleway/raleway-extrabold-webfont"),
    (800, italic, "raleway/raleway-extrabolditalic-webfont"),
    (900, normal, "raleway/raleway-black-webfont"),
    (900, italic, "raleway/raleway-blackitalic-webfont")
  ),
  'Open Sans': (
    (300, normal, "opensans/opensans-light-webfont"),
    (300, italic, "opensans/opensans-lightitalic-webfont"),
    (400, normal, "opensans/opensans-regular-webfont"),
    (400, italic, "opensans/opensans-italic-webfont"),
    (600, normal, "opensans/opensans-semibold-webfont"),
    (600, italic, "opensans/opensans-semibolditalic-webfont"),
    (700, normal, "opensans/opensans-bold-webfont"),
    (700, italic, "opensans/opensans-bolditalic-webfont"),
    (800, normal, "opensans/opensans-extrabold-webfont"),
    (800, italic, "opensans/opensans-extrabolditalic-webfont")
  )
);




@if(variable-exists(fonts)) {
  @each $name, $properties in $fonts {
    @each $weight, $style, $path in $properties {

      @font-face {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
        src: url($base-font-path + $path + ".woff2") format("woff2"),
             url($base-font-path + $path + ".woff") format("woff");
      }

    }
  }
}
