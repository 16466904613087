.workshop-small {
  display: flex;

  &__visual {
    margin: 0;
    width: 50%;
    font-size: rem(12);
    min-height: 140px;
    display: none;

    @include above($desktop) {
      display: block;
    }

    img {
      display: block;
      margin: 0;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include above($desktop) {
      padding-left: 20px;
      width: 50%;
    }
    
    a {
      align-self: flex-end;
    }
  }

  &__title {
    $fs: 18;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 900;
    letter-spacing: ls(2.2, $fs);
    margin: 0;
    color: $red;
    text-transform: uppercase;
  }

  &__description {
    $fs: 15;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 300;
    line-height: lh(24, 16);
    margin: 0;
    flex-grow: 1;
  }
}