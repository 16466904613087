$base-margin: 20px;
$base-margin-lrg: 40px;

.constricted {
  max-width: 1440px;
  margin: auto;
  position: relative;
  padding: 0 $base-margin;

   @include above($tablet) {
     padding: 0 $base-margin-lrg;
   }
}

.grid {
  @include above($tablet-xs) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -($base-margin-lrg);
    margin-right: -($base-margin-lrg);
    padding: 0 ($base-margin-lrg / 2);
  }
}


.col {
  position: relative;
  margin: $base-margin 0;
  width: 100%;

  @include above($tablet-xs) {
    margin-left: ($base-margin-lrg / 2);
    margin-right: ($base-margin-lrg / 2);
  }
  

  &_3 {
    @include above($tablet-xs) {
      width: calc(50% - #{$base-margin-lrg});
    }

    @include above($desktop-sm) {
      width: calc(33.333% - #{$base-margin-lrg});
    }
  }


  &_4 {
    @include above($tablet-xs) {
      width: calc(50% - #{$base-margin-lrg});
    }

    @include above($desktop-sm) {
      width: calc(33.333% - #{$base-margin-lrg});
    }

    @include above($desktop) {
      width: calc(25% - #{$base-margin-lrg});
    }
  }
}