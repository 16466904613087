
.filter {
  $text-color: $blue;
  $fs: 16;

  @include notch-top-left-bottom-right(14px);
  font-family: $title-font-family;
  font-size: rem(16);
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 rem(5) 0 rem(20);
  background-color: $grey;
  color: $text-color;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center;
  
  & + .filter-tag,
  & + & { margin-left: 10px; }

  &__text {
    display: inline-block;
    margin-right: rem(5);
  }

  .icn {
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
    margin-left: rem(-5);

    svg {
      transition-property: fill, transform;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-delay: 0s;
      fill: $text-color;
    }
  }

  &:hover,
  &:focus {
    background-color: $blue;
    color: $white;
    
    .icn {
      transform: rotate(-180deg);
    }
    .icn svg {
      fill: $white;
    }
  }

}


.filter-tag {
  $text-color: $blue;
  $fs: 16;

  @include notch-top-left-bottom-right(14px);
  font-family: $title-font-family;
  font-size: rem(16);
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 rem(20);
  // background-color: $grey;
  color: $text-color;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center;
  position: relative;

  & + & { margin-left: 10px; }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 20px;
    z-index: 0;
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-image: url('../img/ui/btn-blue-outline.svg');
  }

  &::before {
    left: 0;
    width: 100%;
  }

  &::after {
    right: 0;
    transform: scale(-1);
  }

  &__text {
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  .icn {
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
    margin-left: rem(-5);

    svg {
      transition-property: fill, transform;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-delay: 0s;
      fill: $text-color;
    }
  }

  &.is-selected,
  &:hover,
  &:focus {
    background-color: $blue;
    color: $white;
  }

}