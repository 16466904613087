.event-preview {
  position: ralative;

  &__visual {
    margin: 0 15px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: relative;
    margin-top: -30px;
  }

  &__title {
    $fs: 21;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 900;
    line-height: lh(24, $fs);
    letter-spacing: ls(1.75, $fs);
    text-transform: uppercase;
    margin: 0;
    color: $white;
  }

  &__date {
    margin: 0;
    color: $white;
  }
}


.event-meta {
  height: 50px;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  overflow: hidden;

  .event-meta__logo-bar{
    position: absolute;
    height: 34px;
    padding:  5px 15px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -20px;
      background-color: $white;
      transform: skew(-45deg);
      box-shadow: 0 2px 4px rgba(#000, .05);
    }
  }

  .btn {
    position: absolute;
    right: 0;
    top: 10px;
    color: $blue;
    box-shadow: 0 2px 4px rgba(#000, .05);
  }

  .event-logos {
    position: relative;
    z-index: 2;

    img {
      display: inline-block;
      margin: 0;
      width: auto;
      height: 24px;
    }
  }
}



// .event-preview--alt {

//   .event-preview__content {
//     mix-blend-mode: multiply;
//   }
  
//   .event-preview__content::before {
//     display: none;
//   }

//   .event-preview__title {
//     color: $red;
//   }

//   .event-preview__date {
//     color: $blue;
//   }
// }