.nav--secondary {
  $fs: 13;
  font-size: rem($fs);
  letter-spacing: ls(0.68, $fs);
  font-family: $title-font-family;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
  position: relative;

  li {  
    &.is-active,
    &:hover,
    &:focus {
      a { color: $red; }
    }
  }

  @include above($tablet) {
    height: 40px;
    color: $white;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;

    ul {
      height: 100%;
      text-align: right;
      display: flex;
      position: absolute;
      right: 0;
      padding: 0 30px 0 20px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -20px;
        left: 0;
        bottom: 0;
        background-color: $blue;
        transform: skew(-45deg);
      }
    }

    li {
      display: flex;
      align-items: center;
      padding: 0 10px;
      position: relative;
      z-index: 1;
    }
  }

  @include above($desktop) {
    margin-right: 15px;
    width: auto;
    height: auto;
    color: $blue;
    position: relative;
    border: none;
    
    ul {
      text-align: left;
      height: auto;
      display: inline-block;
      position: relative;
      right: auto;
      padding: 0;

      &::before {
        display: none;
      }
    }

    li { 
      padding: 0 10px;
      display: inline-block;
    }
  }

  @include above($desktop-md) {
    margin-right: 25px;

    li { padding: 0 14px; }
  }

  @include above($desktop-hd) {
    margin-right: 35px;

    li { padding: 0 20px; }
  }
}