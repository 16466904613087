// ==========================================================================
// Base
// Base rules are the defaults. They are almost exclusively single element
// selectors but it could include attribute selectors, pseudo-class selectors,
// child selectors or sibling selectors. Essentially, a base style says that
// wherever this element is on the page, it should look like this. ==========================================================================

nav {
  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}


button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
}