.info-block {
  position: ralative;
  padding: 20px;
  color: $white;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $red;
    mix-blend-mode: multiply;
    z-index: 0;
  }

  a:not(.btn) {
    color: inherit;
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &__title {
    $fs: 21;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 900;
    line-height: lh(24, $fs);
    letter-spacing: ls(1.75, $fs);
    text-transform: uppercase;
    margin: 0;
    color: inherit;
  }

  &__jobtitle,
  &__description,
  &__date {
    $fs: 16;
    margin: em(10, $fs) 0;
    line-height: lh(24, $fs);
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
  }
}

.event-preview--alt .info-block,
.info-block--alt {
  mix-blend-mode: multiply;
  color: $blue;

  &::before {
    display: none;
  }

  .info-block__title {
    color: $red;
  }

  .btn--small {
    color: $white;
    background-color: $red;

    @include btn-hover() {
      background-color: $blue;
    }
  }
}