.form--footer-newsletter {
  padding: 0;
  max-width: 233px;

  .field-wrapper {
    position: relative;
    margin: 0;
    display: flex;
  }

  .field {
    width: calc(100% - 40px);

    input {
      $fs: 13;
      background-color: $darkblue;
      color: $white;
      font-size: rem($fs);
      letter-spacing: ls(1.38, $fs);
      font-family: $title-font-family;
      padding: 0 em(10, $fs);

      @include placeholder() {
        color: $white;
      }
    }
  }
  .field::after {
    display: none;
  }

  .btn--small {
    &, & .icn {
      width: 40px;
      height: 40px;
    }

    @include btn-hover() {
      background-color: $darkblue;
      .icn { width: 40px; }
    }
  }

}