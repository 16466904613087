$height: 274px;
$offset-left: 200px;
$offset-right: 340px;

.hero-homepage {
  position: relative;
  height: $height;
  background-image: url('../img/temp/header.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.hero-homepage__doors {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
 

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    transform: skew(45deg);
    z-index: 0;
  }

  &::before {
    left: -($offset-left);
    background-color: $blue;
  }
  &::after {
    right: -($offset-right);
    background-color: $red;
  }

  .lines {
    position: absolute;
    right: 0;
    height: 100%;
    left: 50%;
    margin-left: 640px;
    z-index: 1;
  }
}

.hero-homepage__doors {
  &.--blend {
    z-index: 0;
    mix-blend-mode: multiply;

    &::before {
      left: -($offset-left - 70px);
    }

    &::after {
      right: -($offset-right - 70px);
    }
  }

  &.--opaque {
    z-index: 1;
  }
}


.hero-homepage__inner {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}


.hero-homepage__title {
  $fs: 45;
  font-family: $title-font-family;
  font-size: rem($fs);
  font-weight: 300;
  letter-spacing: ls(2.15, $fs);
  text-transform: uppercase;
  color: $white;
}