.site-footer {
  position: relative;
  padding-top: 23px;
  color: $white;
  line-height: lh(24, 16);
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 100%;
    mix-blend-mode: overlay;
    background-color: $red;
  }

  &__title {
    $fs: 16;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 700;
    margin: 0 0 15px 0;
  }

  &__top {
    background-color: $blue;
    padding-top: 70px;
    padding-bottom: 35px;
  }

  &__bottom {
    border-top: 1px solid #424866;
    background-color: $darkblue;

    .site-footer__inner {
      height: 80px;
      display: flex;
      align-items: center;
    }
    
  }
}


.site-footer {
  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__menu {
    width: 234px;
  }

  &__newsletter {
    flex-grow: 1;
    padding-right: 40px;

    &__inner {
      max-width: 330px;
    }
  }

  &__address {
    width: 300px;
  }

  a {
    color: $white;
  }
}