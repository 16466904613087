.nav--social {
  a {
    position: relative;
    display: block;
    width: 35px;
    height: 35px;
    border: 2px solid $white;
    border-radius: 50%;
    overflow: hidden;
    backgroun-color: transparent;
    transition-property: background-color;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }

    .icn {
      position: absolute;
      top: -2px;
      left: -2px;
    }

    svg { 
      fill: $white;
    }

    &:hover,
    &:focus {
      background-color: rgba($white, .2);
    }
  }
}