body.mobile {
  left: 0;
  position: relative;
  width: 100%;
  transition: left .3s ease-in-out;
}

body.mobile.show-menu {
  left: -100vw;

  .off-screen-menu {
    left: 0;
  }
}


[data-mobile-menu="open"] {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  @include above($tablet) {
    display: none;
  }
}

.off-screen-menu {
  background-color: $blue;
  color: $white;
  padding: 40px;
  position: fixed;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  left: 100vw;
  z-index: 999;
  transition: left .3s ease-in-out;

  @include above($tablet) {
    display: none;
  }
}

