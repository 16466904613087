.nav--primary {
  $fs: 15;
  font-size: rem($fs);
  letter-spacing: ls(0.79, $fs);
  font-family: $title-font-family;
  font-weight: 700;
  text-transform: uppercase;
  flex-grow: 1;
  margin-left: 10px;

  li { 
    padding: 0 5px;
    
    &.is-active,
    &:hover,
    &:focus {
      a { color: $red; }
    }
  }

  @include above($tablet) {
    margin-left: 20px;

    li { padding: 0 10px; }
  }

  @include above($desktop) {
    margin-left: 20px;

    li { padding: 0 10px; }
  }

  @include above($desktop-md) {
    margin-left: 40px;

    li { padding: 0 14px; }
  }

  @include above($desktop-hd) {
    margin-left: 80px;

    li { padding: 0 24px; }
  }
}